const initialState = {
    basvurudata: []
}
const basvurureducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_BASVURU":
            return { ...state, basvurudata: action.basvurudata };
        default:
            return state;
    }
}
export default basvurureducer;

