const initialState = {
    playerData:[],getPlayerSubbranchData:[],detailplayersData:[],branchData:[]
}

const PlayerReducers = (state = initialState,action)=>{
    switch(action.type){
        case "GetAllPlayers":
            return {...state,playerData:action.alldata};
        case "GetPlayerSubbranchById":
            return {...state,getPlayerSubbranchData:action.alldata};
        case "GetPlayersById":
            return {...state,detailplayersData:action.alldata};
        case "GetAllMainBranch":
            return {...state,branchData:action.alldata};
        default:
        return state;
    }
}

export default PlayerReducers;