// ** Redux Imports
import { combineReducers } from 'redux'
import NewsReducer from '../News/reducer'


import PlayerReducers from '../Players/reducer'



import MainBranchReducers from '../MainBranchs/reducer'

import basvurureducer from '../Basvuru/reducer'

import formCategoryReducer from '../Category/reducer'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'



const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  NewsReducer,
  PlayerReducers,
  MainBranchReducers,
  basvurureducer,
  formCategoryReducer

})

export default rootReducer
