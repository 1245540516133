const initialState = {
    formCategoryData: [],
    formCategoryCheckActiveData: []
}
const formCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ALL_FORMCATEGORY":
            return { ...state, formCategoryData: action.formCategoryData };
        case "GET_CHECKACTIVE_FORMCATEGORY":
            return { ...state, formCategoryCheckActiveData: action.formCategoryCheckActiveData };
        default:
            return state;
    }
}
export default formCategoryReducer;

