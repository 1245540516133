const initialState = {
    AddImageData: [], AddData: [],
    GetData: [], GalleryData: [], ImageData: []
}
const MainBranchReducers = (state = initialState, action) => {
    switch (action.type) {
        case "AddMainBranchImage":
            return { ...state, AddImageData: action.alldata };
        case "addMainBranch":
            return { ...state, AddData: action.alldata };
        case "GetAllBranch":
            return { ...state, GetData: action.alldata };
        case "getImageIdList":
            return { ...state, GalleryData: action.alldata };
        case "getMainBranchImage":
            return { ...state, ImageData: action.alldata };
        default:
            return state;
    }
}
export default MainBranchReducers;

