const initialState = {
    newsData: [], newsPaginationsData: [],
    addNews: [], detailnewsData: [], searchData: []
}
const newsReducers = (state = initialState, action) => {
    switch (action.type) {
        case "GetAllNews":
            return { ...state, newsData: action.alldata };
        case "GetAllNewsPaginations":
            return { ...state, newsPaginationsData: action.alldata };
        case "AddNews":
            return { ...state, addNews: action.alldata };
        case "GetNewsById":
            return { ...state, detailnewsData: action.alldata };
        case "GetNewsListSearch":
            return { ...state, searchData: action.alldata };

        default:
            return state;
    }
}
export default newsReducers;

